import React from "react";
import Link from "@/components/link";
import styles from "./PageFoot.module.css";
import FooterLinkGroup from "./components/FooterLinkGroup";
import FooterTooltipGroup from "./components/FooterTooltipGroup";

import baiduQrcode from "@/public/assets/images/baidu.png";
import kefuQrcode from "@/public/assets/images/kefu.png";
import gongzhonghaoQrcode from "@/public/assets/images/gongzhonghao.jpg";
import xiaochengxuQrcode from "@/public/assets/images/xiaochengxu.jpg";

export default function PageFoot({
  activityLink,
  newsLink,
  websiteInfo,
}: {
  activityLink: string;
  newsLink: string;
  websiteInfo: SiteInfo;
}) {
  return (
    <footer className={styles.page_foot}>
      <div className={styles.page_foot__content}>
        {/* 页脚 */}
        <ul className={styles.page_foot__link}>
          <FooterLinkGroup
            title="发展历程"
            links={[
              { href: "/about", text: "关于我们" },
              { href: "/about/society", text: "关于学会" },
              { href: "/about/charter", text: "架构及章程" },
              { href: "/about/building", text: "党建园地" },
              { href: "/about/job", text: "工作机会" },
              { href: "/about/contact", text: "联系我们" },
            ]}
          />
          <FooterLinkGroup
            title="学术活动"
            links={[
              { href: "/rare_disease", text: "罕见病大会" },
              { href: activityLink, text: "活动" },
              { href: newsLink, text: "动态" },
            ]}
          />
          <FooterLinkGroup
            title="医疗临床及数据服务"
            links={[
              { href: "https://upwards.chard.org.cn/", text: "国家财政专项" },
              { href: "https://app.chard.org.cn/", text: "中国罕见病综合云服务平台" },
              { href: "https://www.nrdrs.org.cn/", text: "中国国家罕见病注册系统" },
              { href: "https://zhibao.nrdrs.org.cn/", text: "中国罕见病诊疗服务信息系统" },
              { href: "https://app.chard.org.cn/", text: "软骨发育不全（ACH）全国多中心登记研究" },
            ]}
          />
          <FooterTooltipGroup
            title="帮助与支持"
            tooltips={[
              { code: kefuQrcode, title: "在线客服", content: "扫码联系在线客服" },
            ]}
          />
          <FooterTooltipGroup
            title="移动平台"
            tooltips={[
              { code: gongzhonghaoQrcode, title: "微信公众号", content: "扫码关注微信公众号" },
              { code: xiaochengxuQrcode, title: "微信小程序", content: "扫码进入微信小程序" },
              { code: baiduQrcode, title: "百度小程序", content: "扫码进入百度小程序" },
            ]}
          />
        </ul>
        {/* 版权声明与备案号信息 */}
        <div className={styles.page_foot__info}>
          {websiteInfo.copyright}{" "}
          <Link className={styles.linkICP} href={websiteInfo.icpUrl} target="_blank">
            {websiteInfo.icpNumber}
          </Link>
        </div>
      </div>
    </footer>
  );
}